<template>
  <v-layout wrap>
    <v-flex xs8 style="padding: 10px;">
    <v-card id="main-seats-map">
      <v-responsive :aspect-ratio="16/9">
        <v-card-text style="padding: 0;">
          <canvas 
            v-if="width && height"
            ref="canvas" 
            :width="200"
            :height="100"
            style="background-color:#CCCCCC80;"
          />
        </v-card-text>
      </v-responsive>
    </v-card>
    </v-flex>
    <v-flex xs4 style="padding: 10px;">
      <v-btn
        @click="handleAddItem"
        block
      >
        add
      </v-btn>
    </v-flex>
  </v-layout>
  <!--canvas 
    ref="canvas" 
    :width="width"
    :height="height"
  /-->
</template>
<script>
import { fabric } from 'fabric'
//import smService from '@/services/seatsMap'
export default {
  props: {
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    canvas: null,
  }),
  watch: {
    width (v) {
      if (this.canvas && v)this.canvas.width = v
    },
  },
  mounted () {
    this.prepareData()
    /*
    this.$nextTick(() => {
      alert('a')
      window.addEventListener('resize', this.handleResize()) 
      this.prepareData()
      this.handleAddItem()
    })
    */
  },
  /*beforeDestroy() { 
    window.removeEventListener('resize', this.handleResize()) 
  },*/
  methods: {
    prepareData () {
      this.canvas = new fabric.Canvas(this.$refs.canvas)
    },
    handleResize () {
      alert('c')
      //const w = document.getElementById('main-seats-map').offsetWidth
    },
    handleAddItem () {
        const rect = new fabric.Rect({
          fill: 'red',
          width: 200,
          height: 200
        })
        this.canvas.add(rect)
      //console.log(this.canvas.getObjects())
    },
  },
}
</script>

